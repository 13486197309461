<template>
  <div class="flex w-full justify-center items-center border-grayed"
     :class="[noBorder?'':fullBorderOnMobile?'border':'border-t border-b',
        noBorder?'':!small?'lg:border lg:rounded-lg ':'',
        bgTransparent?'bg-transparent':'bg-white'
     ]"
  >
    <div v-if="small" class="flex flex-col lg:flex-row gap-3 lg:gap-14 lg:items-center">
      <div class="loader"
        :class="[picture?pictureHeight:'', {'picture-loader': picture}]">
      </div>
      <template v-if="text">
        <div class="text-s text-center text-grayed justify-center">
          {{ text }}
        </div>
      </template>
    </div>
    <template v-else>
      <div class="loaderCirclePicture">
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: "Loader",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    fullBorderOnMobile: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
	  bgTransparent: {
		  type: Boolean,
		  default: false,
	  },
    picture: Boolean,
    text: {
      type: String,
      default: null
    },
	  pictureHeight: {
		  type: String,
		  default: 'h-82',
	  }
  },
  data () {
    return {

    }
  },
}
</script>