import Polish from "vue-tailwind/dist/l10n/pl";
import Ukrainian from "vue-tailwind/dist/l10n/uk";
import English from "vue-tailwind/dist/l10n/default";

import {
  TInput,
  TTextarea,
  TSelect,
  TRadio,
  TCheckbox,
  TButton,
  TInputGroup,
  TCard,
  TAlert,
  TModal,
  TDropdown,
  TRichSelect,
  TPagination,
  TTag,
  TRadioGroup,
  TCheckboxGroup,
  TTable,
  TDatepicker,
  TToggle,
  TDialog,
} from 'vue-tailwind/dist/components';


const tailwindComponents = {
  TAlert,
  TButton,
  TCard,
  TCheckbox,
  TCheckboxGroup,
  TDatepicker,
  TDialog,
  TDropdown,
  TInput,
  TInputGroup,
  TModal,
  TPagination,
  TRadio,
  TRadioGroup,
  TRichSelect,
  TSelect,
  TTable,
  TTag,
  TTextarea,
  TToggle,
}

const settings = {
  't-select': {
    component: TSelect,
    props: {
      fixedClasses: 'border  block w-full h-32 px-3 py-1 transition duration-100 text-s text-black ease-in-out rounded shadow-sm ' +
        ' focus:ring-grayed focus:ring-2 focus:outline-none focus:ring-opacity-50 '+
        ' disabled:opacity-50 disabled:cursor-not-allowed  ' +
        ' bg-grayedLight placeholder-grayed ',
      classes:'text-black bg-grayedLight border border-grayed placeholder-grayed'
    }
  },
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'block w-full h-32 px-3 py-3 transition duration-100 focus:outline-none text-s ease-in-out rounded disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black bg-grayedLight placeholder-grayed border border-grayed focus:ring-grayed focus:ring-2',
      variants: {
        danger: 'border-badgeError bg-grayedLight focus:ring-error focus:ring-2 placeholder-error',
        success: 'border-badgeSuccess bg-badgeSuccessPale placeholder-grayed',
        basicInfo: 'bg-grayedLight placeholder-grayed border border-none focus:ring-grayed focus:ring-2',
        subscribe: 'bg-white text-black placeholder-grayed border border-none focus:ring-grayed focus:ring-2'
      }
    }
  },
  't-card': {
    component: TCard,
    props: {
      classes: {
        wrapper: 'p-3 lg:p-6 bg-white border-t border-b border-grayed lg:border lg:rounded-lg',
        header: 'border-b p-3 rounded-t',
        body: 'w-full',
        footer: 'border-t p-3 rounded-b',
      },
      variants: {
        contact: {
          wrapper: 'px-3 pb-3 pt-3 lg:px-6 lg:pb-6 bg-white border-t-0 rounded-b rounded-t-none',
        },
        form: {
          wrapper: 'p-3 lg:p-6 bg-white w-full lg:w-form-default',
          body: '',
        },
        auth: {
          wrapper: 'p-3 lg:p-6 bg-white w-full lg:w-main-container mb-16 gap-6 border-t border-b border-grayed lg:border lg:rounded-lg',
          body: ''
        },
        danger: {
          wrapper: 'bg-red-100 text-red-600 border-red-600',
          header: 'border-red-200 text-red-600',
          footer: 'border-red-200 text-red-600',
        },
        static: {
          wrapper: 'text-black bg-transparent w-1/2',
        },
        homepage: {
          wrapper: 'text-black bg-transparent p-3 lg:p-6 w-full lg:w-1200 mx-auto shadow-none border-none',
        },
        transparent: {
          wrapper: 'p-3 lg:p-6 shadow-none bg-transparent'
        },
      }
    }
  },
  't-tag': {
    component: TTag,
    props: {
      fixedClasses: 'flex inline-flex font-normal items-center border leading-normal rounded text-white',
      classes: '',
      variants: {
        // badgeError:    'px-2 text-s bg-badgeErrorPale border-badgeError',
        badgeError:    'px-2 text-s bg-badgeError border-badgeError',
        // badgeInfo:     'px-2 text-s bg-badgeInfoPale border-badgeInfo',
        badgeInfo:     'px-2 text-s bg-badgeInfo border-badgeInfo',
        // badgeSuccess:  'px-2 text-s bg-badgeSuccessPale border-badgeSuccess',
        badgeSuccess:  'px-2 text-s bg-badgeSuccess border-badgeSuccess',
        badgeNeutral:  'px-2 text-s bg-white border-black',
        counterSuccess:'px-1 text-xxxs bg-badgeSuccessPale border-badgeSuccess rounded-full text-badgeSuccess',
        counterNeutral:'px-1 text-xxxs bg-white border-black text-black',
      }
    }
  },
  't-textarea': {
    component: TTextarea,
    props: {
      fixedClasses: 'block w-full h-48 px-3 py-3 transition duration-100 focus:outline-none focus:border-transparent text-s ease-in-out rounded disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black placeholder-grayed border border-grayed focus:ring-black focus:ring-2',
      variants: {
        danger: 'border-badgeError bg-grayedLight focus:ring-error focus:ring-2 placeholder-error',
        success: 'border-badgeSuccess bg-badgeSuccessPale placeholder-gray-600 text-black',
      }
    }
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'inline-flex relative items-center transition duration-100 ease-in-out ' +
        'focus:outline-none hover:opacity-80 border border-solid border-transparent ' +
        'disabled:opacity-50 disabled:cursor-default',
      classes: 'w-min text-white focus:ring-2 ring-grayed bg-black rounded px-2 py-1.5 button-base ',
      variants: {
        buttonRed: 'text-white bg-black rounded border border-transparent px-2 py-1.5 button-base ' +
          'hover:text-white hover:bg-redAccent disabled:text-white disabled:bg-black disabled:opacity-50',
        buttonRedLarge: 'text-white bg-black rounded border border-transparent ' +
          'hover:text-white hover:bg-redAccent disabled:text-white disabled:bg-black disabled:opacity-50 ' +
          'px-3 py-1 text-base',
        buttonRedOutlined: 'rounded px-2 py-1.5 button-base text-base border border-redAccent bg-white text-redAccent ' +
          'disabled:text-black disabled:border disabled:border-black disabled:bg-grayedLight ' +
          'disabled:pointer-events-none pointer-events-auto ' +
          'hover:border-redAccent hover:bg-redAccentPale hover:text-redAccent',
        buttonRedOutlinedLarge: 'rounded px-3 py-1 text-base border border-redAccent bg-white text-redAccent ' +
          'disabled:text-black  disabled:border disabled:border-black disabled:bg-grayedLight ' +
          'disabled:pointer-events-none pointer-events-auto ' +
          'hover:border-redAccent hover:bg-redAccentPale hover:text-redAccent',
        contactLink: 'underline',
        disabled: 'text-white cursor-normal bg-grayed rounded px-2 py-1.5 button-base',
        error: 'text-white bg-red-500 border border-transparent hover:bg-red-600',
        flag: 'bg-transparent px-0 py-0 h-12 text-black flag-icon lg:ml-0 flex text-white rounded-full block transition duration-100\n' +
          ' ease-in-out border border-transparent focus:border-none focus:ring-0 focus:ring-none focus:outline-none\n' +
          ' focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed items-center',
        icon: 'bg-transparent disabled:opacity-50',
        iconNoHover: '',
        iconDisabled: 'opacity-70',
        iconDropdown: 'flex items-center',
        iconLink: 'inline-flex items-center gap-2 text-current hover:underline font-md',
        iconProfile: 'inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2 hover:opacity-100',
        iconProfileBigger: 'inline-flex gap-2 items-center bg-transparent px-0 text-xl font-semibold leading-normal hover:opacity-100',
        iconProfileDanger: 'inline-flex gap-2 items-center font-semibold text-s leading-normal mb-2 hover:opacity-100',
        iconThreadAction: 'bg-transparent px-0 opacity-100 lg:opacity-20 hover:opacity-100 gap-2 lg:gap-0 text-lg',
        homepageLink: 'text-white text-sm disabled:text-redAccent disabled:opacity-100',
        link: 'hover:opacity-70  hover:text-redAccentLight disabled:text-grayed disabled:opacity-100 underline',
        linkFooterWhite: 'text-white disabled:text-grayed disabled:opacity-70 hover:underline px-0 lg:px-4',
        navLink: 'hover:opacity-60 text-current hover:text-redAccent lg:px-0 disabled:text-redAccent disabled:opacity-100',
        outlined: 'border border-black rounded bg-white text-black px-2 py-1.5 button-base ' +
          'disabled:text-black disabled:border-black' +
          'hover:opacity-90 hover:border-black hover:bg-grayedLight hover:text-black ',
        rounded: 'rounded-full',
        routerLink: 'hover:text-redAccentLight disabled:text-black text-left m-0 py-0 cursor-pointer underline',
        routerLinkBreadcrumb: 'hover:text-redAccentLight font-bold text-left m-0 py-0 cursor-pointer',
        secondary: 'text-gray-800 bg-white border border-gray-300 hover:text-gray-600',
        small: 'text-xs px-2 py-0',
        success: 'text-white bg-green-500 border border-transparent hover:bg-green-600 disabled:bg-gray-200 disabled:border-gray-500 disabled:text-gray-500',
        textWithIcon: 'hover:text-redAccent',
        underline: 'text-current underline hover:text-redAccentLight hover:underline',
      }
    }
  },
  't-icon-button': {
    component: TButton,
    props: {
      fixedClasses: 'absolute items-center transition duration-100 ease-in-out focus:outline-none hover:opacity-70 border border-solid border-transparent disabled:opacity-70 disabled:cursor-default focus:ring-none ring-transparent',
      classes: 'text-white bg-transparent rounded px-0 button-base ',
      variants: {
        icon: 'bg-transparent hover:text-redAccentLight hover:opacity-70 disabled:text-redAccentLight disabled:opacity-100',
      }
    }
  },
  't-pagination': {
    component: TPagination,
    props: {
      classes: {
        wrapper: 'table border-collapse text-center bg-white mx-auto',
        element: 'w-8 h-8 border border-gray-200 table-cell hover:border-gray-100',
        activeElement: 'w-8 h-8 border border-gray-200 border-black table-cell hover:border-gray-600 hover:text-white',
        disabledElement: 'w-8 h-8 border border-gray-200 table-cell',
        ellipsisElement: 'w-8 h-8 border border-gray-200 hidden lg:table-cell',
        activeButton: 'bg-black w-full h-full text-white hover:opacity-70 transition duration-100 ease-in-out focus:ring-0 focus:ring-gray-500 focus:outline-none focus:ring-opacity-70',
        disabledButton: 'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button: 'hover:bg-black hover:text-white w-full h-full transition duration-100 ease-in-out focus:ring-0 focus:ring-gray-500 focus:outline-none focus:ring-opacity-70',
      },
      variants: {
        posts: {
          wrapper: 'mx-auto text-center flex space-x-2',
          element: 'w-8 h-8 rounded-full',
          activeElement: 'w-8 h-8 rounded-full',
          disabledElement: 'w-8 h-8 rounded-full',
          ellipsisElement: 'w-8 h-8 rounded-full hidden lg:inline',
          activeButton: 'border border-black bg-black w-full h-full rounded-full text-white hover:bg-black transition duration-100 ease-in-out focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-70',
          disabledButton: 'border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out',
          button: 'border border-gray-200 hover:opacity-70 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-70',
          ellipsis: ''
        }
      },
    }
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      classes: {
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute left-0 w-56 rounded mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        button: 'bg-black hover:opacity-75',
      },
      fixedClasses: {
        button: 'flex items-center bg-transparent bg-white text-black',
      },
      variants: {
        dashboard: {
          wrapper: 'flex flex-row bg-white items-center',
          dropdownWrapper: 'relative z-50 text-black',
          dropdown: 'whitespace-nowrap absolute transform mt-5 lg:mt-6 right-1/2 translate-x-1/4 pl-3',
          enterClass: 'opacity-0 scale-95',
          enterActiveClass: 'transition transform ease-out duration-100',
          enterToClass: 'opacity-100 scale-100',
          leaveClass: 'opacity-100 scale-100',
          leaveActiveClass: 'transition transform ease-in duration-75',
          leaveToClass: 'opacity-0 scale-95'
        }
      }
    }
  },
  't-language-select': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'flex items-center text-white rounded-full block transition duration-100 ease-in-out border border-transparent focus:border-transparent focus:ring-0 focus:ring-none focus:outline-none focus:ring-opacity-70 disabled:opacity-70 disabled:cursor-not-allowed',
        wrapper: '',
        dropdownWrapper: 'relative z-10 flex justify-center',
        dropdown: 'space-y-3 w-6 absolute top-0 max-h-40 z-50 overflow-auto',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95'
      },
      classes: {
        button: 'bg-black hover:opacity-75',
        dropdown: 'background-transparent',
      },
      variants: {
      }
    }
  },
  't-radio': {
    component: TRadio,
    props: {
      wrapped: true,
      classes: {
        label: 'text-xs uppercase mx-2 text-gray-700',
        input: 'text-black transition duration-100 ease-in-out border-gray-300 shadow-sm transition duration-150 ease-in-out ' +
          'focus:ring-2 focus:ring-redAccent focus:ring-opacity-50 focus:ring-offset-0 ' +
          'disabled:cursor-not-allowed disabled:opacity-50 ' +
          'checked:text-redAccentLight checked:fill-redAccentLight ' +
          'hover:text-redAccentLight group-hover:cursor-pointer',
        inputWrapper: '',
        wrapper: 'flex items-center',
        labelChecked: '',
        inputWrapperChecked: '',
        wrapperChecked: '',
      },
      fixedClasses: {
        label: 'flex items-center h-full justify-center hidden',
        input: 'cursor-pointer text-black transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-black focus:border-none focus:ring-none focus:ring-black focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed transition duration-150 ease-in-out',
        inputWrapper: '',
        wrapper: 'flex items-center justify-center h-full',
        labelChecked: '',
        inputWrapperChecked: 'flex items-center justify-center',
        wrapperChecked: '',
      },
      variants: {
        known: {
          wrapper: 'flex items-center justify-center h-full w-full hover:bg-badgeSuccessPale cursor-pointer',
          wrapperChecked: 'flex items-center justify-center h-full w-full bg-badgeSuccessPale',
        },
        curious: {
          wrapper: 'flex items-center justify-center h-full w-full hover:bg-badgeInfoPale cursor-pointer',
          wrapperChecked: 'flex items-center justify-center h-full w-full bg-badgeInfoPale',
        },
        noway: {
          wrapper: 'flex items-center justify-center h-full w-full hover:bg-badgeErrorPale cursor-pointer',
          wrapperChecked: 'flex items-center justify-center h-full w-full bg-badgeErrorPale',
        },
        neutral: {
          wrapper: 'flex items-center justify-center h-full w-full hover:bg-badgeDefaultPale cursor-pointer',
          wrapperChecked: 'flex items-center justify-center h-full w-full bg-badgeDefaultPale',
        },
      }
    }
  },
  't-checkbox': {
    component: TCheckbox,
    props: {
      fixedClasses: 'transition duration-100 ease-in-out cursor-pointer rounded shadow-sm ' +
        'focus:border-black focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 ' +
        'disabled:opacity-50 disabled:cursor-not-allowed hover:opacity-70 hover:text-redAccentLight',
      classes: 'text-black border-gray-400 ',
      variants: {
        error: 'text-badgeError border-badgeError',
        success: 'text-badgeSuccess border-badgeSuccess'
      }
    }
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'bg-black z-1001 overflow-auto flex items-center justify-center scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'relative w-full flex flex-row justify-center lg:w-auto my-auto z-100 px-3',
        modal: 'overflow-visible relative rounded-lg bg-white shadow',
        header: 'font-semibold text-base lg:text-xl text-title',
        footer: 'flex flex-row justify-end gap-3 mt-3',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 ' +
          'transition duration-100 ease-in-out focus:ring-none focus:outline-none focus:ring-opacity-50 ' +
          'hover:bg-redAccent07onWhite z-10'
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'overflow-visible mt-20 mb-6 p-3 lg:p-6 w-full lg:w-modal-small',
        body: '',
        header: 'mb-3',
        footer: '',
        close: 'bg-black text-white hover:text-opacity-50',
        closeIcon: 'fill-white h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-70',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      variants: {
        experiences: {
          modal: 'overflow-visible mt-20 mb-6 py-3 lg:py-6 px-1.5 lg:px-3 w-full lg:w-main-container',
          header: 'px-1.5 lg:px-3 mb-0 font-semibold text-base lg:text-xl text-title',
          footer: 'px-1.5 lg:px-3',
        },
        basic: {
          modal: 'overflow-visible mt-20 mb-6 p-3 lg:p-6 w-full lg:w-main-container',
        },
        gallery1: {
          modal: 'overflow-visible mt-20 mb-6 p-3 lg:p-6 w-modal-gallery-1 lg:w-modal-gallery',
          header: 'mb-0 font-semibold text-base lg:text-xl text-title',
        },
        gallery2: {
          modal: 'overflow-visible mt-20 mb-6 p-3 lg:p-6 w-modal-gallery-2 lg:w-modal-gallery',
          header: 'mb-0 font-semibold text-base lg:text-xl text-title',
        },
        gallery3: {
          modal: 'overflow-visible mt-20 mb-6 p-3 lg:p-6 w-modal-gallery-3 lg:w-modal-gallery',
          header: 'mb-0 font-semibold text-base lg:text-xl text-title',
        },
        tableAdmin: {
          modal: 'overflow-visible mt-36 lg:mt-24 mb-6 p-3 lg:p-6 w-full lg:w-modal-table'
        },
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-600',
          close: 'bg-red-50 text-red-600 hover:bg-red-200 border-red-100 border',
          modal: 'overflow-visible bg-white border border-red-100 ',
          footer: 'bg-red-50'
        },
        image: {
          modal: 'overflow-visible mt-20 mb-6 p-0'
        },
        imageAdmin: {
          modal: 'overflow-visible mt-36 lg:mt-24 mb-6 p-0'
        },
        confirm: {
          header: 'hidden',
          modal: 'overflow-visible w-full my-20 p-3 lg:p-6 lg:w-form-default',
        },
        map: {
          header: 'font-semibold text-base lg:text-xl text-title px-3 lg:px-6 pt-3 lg:pt-6',
          modal: 'overflow-visible w-full lg:w-main-container mt-16',
        },
        post: {
          header: 'hidden',
          modal: 'overflow-visible w-full lg:w-main-container mt-16 mb-4',
        },
        profile: {
          header: 'hidden',
          modal: 'overflow-visible w-full lg:w-main-container p-3 lg:p-6 mt-16 mb-4',
        },
        profileAdmin: {
          header: 'hidden',
          modal: 'overflow-visible w-full lg:w-main-container p-3 lg:p-6 mt-36 lg:mt-24 mb-4',
        },
        partners: {
          header: 'hidden',
          modal: 'overflow-visible w-full lg:w-main-container p-3 lg:p-6 mt-16 mb-4',
        }
      }
    }
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      locales: {
        en:  English,
        pl:  Polish,
        uk:  Ukrainian,
      },
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
        navigatorLabel: 'flex items-center py-1',
        navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
        navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
        inputWrapper: 'relative',
        viewGroup: 'inline-flex flex-wrap',
        view: 'w-64',
        calendarDaysWrapper: 'grid grid-cols-7',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input: 'block w-full h-32 px-3 py-3 transition duration-100 focus:outline-none focus:border-transparent text-s ease-in-out rounded disabled:opacity-50 disabled:cursor-not-allowed',
        clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-1 h-6 w-6',
        clearButtonIcon: 'fill-current h-3 w-3',
        timepickerWrapper: 'flex items-center px-4 py-2 space-x-2',
        timepickerTimeWrapper: 'flex items-center space-x-2',
        timepickerTimeFieldsWrapper: 'bg-gray-100 rounded w-full text-right flex items-center border border-gray-100 focus:border-black focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50',
        timepickerOkButton: 'text-black text-s uppercase font-semibold transition duration-100 ease-in-out border border-transparent focus:border-black focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50 rounded cursor-pointer',
        timepickerInput: 'text-center w-8 border-transparent bg-transparent p-0 h-6 text-xs transition duration-100 ease-in-out border border-transparent focus:border-black focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50 rounded',
        timepickerTimeLabel: 'flex-grow text-xs text-gray-500',
        timepickerAmPmWrapper: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-black focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50',
        timepickerAmPmWrapperChecked: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-black focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50',
        timepickerAmPmWrapperDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
        timepickerAmPmWrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
        timepickerAmPmButton: 'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded shadow',
        timepickerAmPmButtonChecked: 'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-full bg-white rounded shadow',
        timepickerAmPmCheckedPlaceholder: 'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
        timepickerAmPmUncheckedPlaceholder: 'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'rounded bg-white border mt-1 inline-flex',
        inputWrapper: '',
        input: 'text-black bg-grayedLight placeholder-grayed border border-none focus:ring-black focus:ring-2',
        clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'pt-2 px-3',
        navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
        navigatorViewButtonIcon: 'fill-current text-gray-400',
        navigatorViewButtonBackIcon: 'fill-current text-gray-400',
        navigatorViewButtonMonth: 'text-gray-700 font-semibold',
        navigatorViewButtonYear: 'text-gray-500 ml-1',
        navigatorViewButtonYearRange: 'text-gray-500 ml-1',
        navigatorLabel: 'py-1',
        navigatorLabelMonth: 'text-gray-700 font-semibold',
        navigatorLabelYear: 'text-gray-500 ml-1',
        navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-gray-400',
        navigatorNextButtonIcon: 'text-gray-400',
        calendarWrapper: 'px-3 pt-2',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
        otherMonthDay: 'text-xs rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay: 'text-xs bg-black text-white w-full h-8 rounded-l-full',
        inRangeLastDay: 'text-xs bg-black text-white w-full h-8 rounded-r-full',
        inRangeDay: 'text-xs bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay: 'text-xs rounded-full w-8 h-8 mx-auto bg-black text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay: 'text-xs rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay: 'text-xs rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-xs rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
        today: 'text-xs rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-black',
        monthWrapper: 'px-3 pt-2',
        selectedMonth: 'text-xs rounded w-full h-12 mx-auto bg-black text-white',
        activeMonth: 'text-xs rounded w-full h-12 mx-auto bg-blue-100',
        month: 'text-xs rounded w-full h-12 mx-auto hover:bg-blue-100',
        yearWrapper: 'px-3 pt-2',
        year: 'text-xs rounded w-full h-12 mx-auto hover:bg-blue-100',
        selectedYear: 'text-xs rounded w-full h-12 mx-auto bg-black text-white',
        activeYear: 'text-xs rounded w-full h-12 mx-auto bg-blue-100'
      },
      variants: {
        danger: {
          input: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
          clearButton: 'hover:bg-red-200 text-red-500'
        }
      },
    }
  }

}

export default {tailwindComponents, settings};
