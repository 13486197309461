<template>
  <div id="app"
    class="flex font-helv text-base leading-normal flex-col min-h-screenMinusHeader bg-pageBackground antialiased"
  >
    <div class="background-logo"/>

    <div class="block fixed w-full z-20">
      <Navigation />
    </div>

    <div class="mx-auto z-10 w-full">
      <slot/>
    </div>

    <footer class="mt-auto z-5">
<!--      :class="{'hidden': ($route.name === 'chats' || $route.name === 'search') && isMobile}"-->
      <Footer :year="year" />
    </footer>

	  <Modal
      v-if="isShowBannedModal"
      :show-modal="isShowBannedModal"
      @clicked="isShowBannedModal = false"
	  >
      <template v-slot:header>
        {{ trans('MODAL_TITLE:AccountBlocked') }}
      </template>
		  <template v-slot:content>
			  <div class="flex flex-col lg:pl-3">
          <div class="text-base ban-reason -mb-2">
            <div v-html="userData.banReason"></div>
            <div class="w-full flex flex-row justify-end text-s text-grayed">
              <span v-if="!isMobile">{{ trans('LABEL:BanDate') }}&nbsp;</span>{{ userData.bannedAt }}
            </div>
          </div>
			  </div>
		  </template>
      <template v-slot:footer>
        <div class="w-full flex flex-col">
          <hr class="w-full border-grayedHR mb-3"/>
          <span class="text-right text-s">
            {{ trans('TEXT:AccountBlockedText2') }} <span @click="goToSpecificRoute(postCategoriesData, 'contact', {name: 'post-show'}, {banned: true})" class="whitespace-nowrap underline cursor-pointer hover:opacity-70 hover:text-redAccent">{{ trans('TEXT:AccountBlockedText3') }}</span>.
          </span>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import themeConfig from '@/themeConfig';
import { mdiHeartOutline } from '@mdi/js';
import Button from "@/utils/crud/components/Button";
import Navigation from "@/layouts/components/navigation/Navigation";
import Footer from "@/layouts/components/footer/Footer";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal.vue";
import {mapGetters, mapMutations} from "vuex";
import {goToSpecificRoute} from "@/common/helpers/utils";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  components: {
	  Modal,
    Button,
    Footer,
    Loader,
    Navigation,
  },
	data() {
		return {
			goToSpecificRoute: goToSpecificRoute,
			isShowBannedModal: false,
		}
	},
	watch: {
		$route (to, from){
			if(from.name !== 'auth-login') {
				this.isShowBannedModal = false
			}
		},
		userData: {
			immediate: true,
			deep: true,
			handler(val) {
				if (val && val.isBanned && !this.modalBannedAlreadyShowed) {
					this.isShowBannedModal = !this.modalBannedAlreadyShowed;
					this.updateModalBannedAlreadyShown(true);
				}
			}
		}
	},
  computed: {
		...mapGetters('account', ['userData', 'modalBannedAlreadyShowed', 'authDone']),
	  ...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('chat', ['notReadCounter']),
    isMobile() {
      return checkIsMobileDevice();
    },
  },
	methods: {
		...mapMutations('account', ['updateModalBannedAlreadyShown'])
	},

  setup() {
    const year = new Date().getFullYear();
    return {
      year,
      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      // Icons
      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.background-logo {
  background: theme('colors.pageBackground');
}

@media (min-width: 1024px) {
  .background-logo {
    width: 100vh;
    height: 100vh;
    position:fixed;
    z-index: 0;
    background: url('../assets/images/logos/logo.png') no-repeat;
    opacity: 0.03;
    top:0;
    left:0;
    background-size: 100vh 100vh;
  }
}

</style>
