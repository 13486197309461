import api from "@/common/services/api";

const state = () => ({
    genderData: null,
    genderDeclaredData: null,
    shapeData: null,
    preferenceData: null,
    orientationData: null,
    experiencesData: null,
    statusesMarital: null,
    statusesParental: null,
    searchWhosData: null,
    searchWhatsData: null,
    locationsData: null,
    allLocationsData: null,
    ratesData: null,
    descriptionStatuses: null,
    postCategoriesData: null,
    loadingPostCategories: true,
    userFiltersData: null,
    stimulantsAndPetsData: null,
})

// getters
const getters = {
    genderData: state => state.genderData,
    genderDeclaredData: state => state.genderDeclaredData,
    shapeData: state => state.shapeData,
    preferenceData: state => state.preferenceData,
    orientationData: state => state.orientationData,
    experiencesData: state => state.experiencesData,
    statusesMarital: state => state.statusesMarital,
    statusesParental: state => state.statusesParental,
    searchWhosData: state => state.searchWhosData,
    searchWhatsData: state => state.searchWhatsData,
    locationsData: state => state.locationsData,
    allLocationsData: state => state.allLocationsData,
    ratesData: state => state.ratesData,
    descriptionStatuses: state => state.descriptionStatuses,
    postCategoriesData: state => state.postCategoriesData,
    postCategoriesDataLoading: state => state.loadingPostCategories,
    userFiltersData: state => state.userFiltersData,
    stimulantsAndPetsData: state => state.stimulantsAndPetsData,
};

//actions
const actions = {
    fetchGenders: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/genders`)
            .then(
                success => {
                    commit('setGenderData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchGenderDeclaredData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/genders-declared`)
            .then(
                success => {
                    commit('setGenderDeclaredData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchShapeData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/shapes`)
            .then(
                success => {
                    commit('setShapeData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchPreferenceData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/preferences`)
            .then(
                success => {
                    commit('setPreferenceData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchOrientationData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/orientations`)
            .then(
                success => {
                    commit('setOrientationData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchExperiencesData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/experiences`)
            .then(
                success => {
                    commit('setExperiencesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchMaritalData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/statuses-marital`)
            .then(
                success => {
                    commit('setMaritalData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchParentalData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/statuses-parental`)
            .then(
                success => {
                    commit('setParentalData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchSearchWhosData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/search-whos`)
            .then(
                success => {
                    commit('setSearchWhosData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchSearchWhatsData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/search-whats`)
            .then(
                success => {
                    commit('setSearchWhatsData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchLocationsData: ({ commit, dispatch}, payload) => {
        api.setHeader();
        api.post(`/dictionaries/locations`, payload)
            .then(
                success => {
                    commit('setLocationsData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchAllLocationsData: ({ commit, dispatch}) => {
        api.setHeader();
        api.post(`/dictionaries/locationsAll`)
          .then(
            success => {
                commit('setAllLocationsData', success.data)
            },
            error => {
                dispatch('alert/error', error, { root: true });
            }
          ).finally(() => {
        });
    },

    fetchRatesData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/rates`)
            .then(
                success => {
                    commit('setRatesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchDescriptionStatuses: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/desc-statuses`)
            .then(
                success => {
                    commit('setDescriptionStatuses', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchPostCategoriesData: ({ commit, dispatch}) => {
        api.setHeader();
        commit('setLoadingPostCategories', true);
        api.get(`/dictionaries/post-categories`)
            .then(
                success => {
                    commit('setPostCategoriesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setLoadingPostCategories', false);
        });
    },

    fetchUserFiltersData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/user-filters`)
            .then(
                success => {
                    commit('setUserFiltersData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchStimulantsAndPetsData: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/dictionaries/stimulants-and-pets`)
            .then(
                success => {
                    commit('setStimulantsAndPetsData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },


}

//mutations
const mutations = {
    setGenderData (state, data) {
        state.genderData = data;
    },

    setGenderDeclaredData (state, data) {
        state.genderDeclaredData = data;
    },

    setShapeData (state, data) {
        state.shapeData = data;
    },

    setPreferenceData (state, data) {
        state.preferenceData = data;
    },

    setOrientationData (state, data) {
        state.orientationData = data;
    },

    setExperiencesData (state, data) {
        state.experiencesData = data;
    },

    setMaritalData (state, data) {
        state.statusesMarital = data;
    },

    setParentalData (state, data) {
        state.statusesParental = data;
    },

    setSearchWhosData (state, data) {
        state.searchWhosData = data;
    },

    setSearchWhatsData (state, data) {
        state.searchWhatsData = data;
    },

    setLocationsData (state, data) {
        state.locationsData = data;
    },

    setAllLocationsData (state, data) {
        state.allLocationsData = data;
    },
    setRatesData (state, data) {
        state.ratesData = data;
    },

    setDescriptionStatuses (state, data) {
        state.descriptionStatuses = data;
    },

    setPostCategoriesData (state, data) {
        state.postCategoriesData = data;
    },

    setLoadingPostCategories (state, value) {
        state.loadingPostCategories = value;
    },

    setUserFiltersData (state, data) {
        state.userFiltersData = data;
    },

    setStimulantsAndPetsData (state, data) {
        state.stimulantsAndPetsData = data;
    },
}

export const dictionaries = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
